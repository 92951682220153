/* ParallaxSection.css */

.parallax-container {
  position: relative;
  width: 100%;
  height: 80vh;
  overflow: hidden;
  background: url("../../../public/assets/testimonial/parallax.png") no-repeat
    center center fixed;

  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align to the left */
}

.parallax-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1; /* Ensure content stays above the background */
  font-family: "poppins";
}

.text-overlay {
  color: white;
  max-width: 600px;
  padding: 20px;
  border-radius: 8px;
  margin-left: -800px;
}

.subheading {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.heading {
  font-size: 3rem;
  margin-bottom: 20px;
}

.price {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: 700;
}

.shop-button {
  padding: 10px 40px;
  background-color: #e9c876; /* Button color */
  color: black;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 2rem;
  transition: background-color 0.3s;
  font-weight: bold;
  width: 300px;
}

.shop-button:hover {
  background-color: #d1a963; /* Hover color */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .parallax-container {
    background-position: 75% 25%;
    background-size: cover;
  }

  .text-overlay {
    padding: 15px;
    margin-left: 10px; /* Adjust for smaller screens */
  }

  .subheading {
    font-size: 1rem;
  }

  .heading {
    font-size: 2rem;
  }

  .price {
    font-size: 1.2rem;
  }

  .shop-button {
    font-size: 0.9rem;
  }
}
